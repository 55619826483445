@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";
@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";
@import "@/dataroom/ui/components/Landing/variables.scss";
@import "@/dataroom/ui/components/Landing/VeriSend/variables.scss";

.homeWrp {

  .intro {
    margin-bottom: 300px;

    &:before {
      content: "";
      position: absolute;
      z-index: -1;
      width: 100%;
      transform: skewY(-5.8deg);

      height: 906px;
      top: -185px;
      background: $tenantVeriSendColor;

      @include mqMaxWidth(1260px) {
        top: -110px;
      }

      @include mqMaxWidth($screenM) {
        height: 85%;
      }

      @include mqMaxWidth($screen640) {
        top: -40px;
        height: 75%;
      }
    }

    @include mqMaxWidth($screenM) {
      margin-bottom: 0;
    }
  }

  .introAnimationContainer {
    box-shadow: none;
    overflow: unset;
    background: none;
    z-index: 100;
  }

  .title,
  .description {
    color: #fff;

    span {
      color: #fff;
    }
  }
}

.introPattern {
  bottom: -30px;
}

.description {
  width: 85%;

  @include mqMaxWidth($screenM) {
    width: 100%;
  }
}

.introAnimationShadow {
  border-radius: 5px;
  box-shadow: 0 10px 55px rgba(0, 0, 0, 0.2);
  position: absolute;
  z-index: -1;
  top: 0;
  right: 28px;
  bottom: 38px;
  left: 0;
}

.introContent {
  top: 0;
  position: relative;

  @include mqMaxWidth($screenM) {
    top: 0;
  }
}

.homeWrp .paperPlane {
  position: absolute;
  left: 0;
  right: 0;
  top: 595px;
  margin: 0 auto;
  max-width: 860px;
  width: 100%;
  height: 170px;

  .paperPlane1 {
    position: absolute;
    bottom: 0;
    height: 124px;
    right: 70px;
    width: 48%;

    @include mqMaxWidth($screen640) {
      right: 10px;
      top: 28%;
      height: 50px;
    }
  }

  .paperPlane2 {
    position: absolute;
    height: 50px;
    top: 84px;
    left: 0;
    width: 19%;

    @include mqMaxWidth($screen640) {
     left: 40px;
      top: 52%;
    }
  }

  .paperPlane3 {
    position: absolute;
    width: 82px;
    height: 25px;
    top: 0;
    left: 30%;

    @include mqMaxWidth($screen640) {
      height: 16px;
      top: 30%;
      left: 20%;
    }
  }

  @include mqMaxWidth($screenS) {
    bottom: 5px;
  }

  @include mqMaxWidth(720px) {
    bottom: 28px;
  }

  @include mqMaxWidth($screen640) {
    bottom: 18%;
    top: auto;
  }
}

.button {
  padding: 0 20px;
}
