@import "../base.scss";

.pageContainer {
  padding-top: 66px;
  display: block;
  width: 100%;
  background-color: $baseBackgroundColor;
  overflow: hidden;

  @include mqMaxWidth($screenM) {
    overflow: visible;
  }

  @include mqMaxWidth($screen640) {
    min-height: calc(100vh - 66px);
  }
}
